import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#05152a"}}>
                <div>
                    <img style={{display: "block", margin: "0 auto", width: "100%"}} alt="steve on tv books" src="https://kajabi-storefronts-production.global.ssl.fastly.net/kajabi-storefronts-production/themes/3210473/settings_images/VHz9UuaJQF6UTKACuuBh_steveontv0.png"/>
                </div>
            </div>
        )
    }
}
